export default class Tabs {
    constructor(root) {
        this.root = root;
        this.buttons = this.root.querySelectorAll('.js-tab-button');
        this.tabs = this.root.querySelectorAll('.js-tab');

        this.buttons.forEach((button) => {
            button.addEventListener('click', (event) => {
                this.buttons.forEach((button) => {
                    button.classList.remove('--active');

                    if(button.dataset.tabName == event.target.dataset.tabName) {
                        button.classList.add('--active');
                    }
                });

                this.tabs.forEach((tab) => {
                    tab.classList.add('--hidden');

                    if(tab.dataset.tabName == event.target.dataset.tabName) {
                        tab.classList.remove('--hidden');
                    }
                });
            });
        });

        //show first tab on load
        this.buttons[0].classList.add('--active');
        this.tabs[0].classList.remove('--hidden');
    }
}
