import GetData from './GetData';
import Cookies from './Cookies';

export default class Editor {
    constructor(root) {
        this.root = root;
        this.frame = this.root.querySelector('.js-editor-frame');
        this.editors = this.root.querySelectorAll('.js-editor-area');
        this.save_btn = this.root.querySelector('.js-editor-save');
        this.api = new GetData;
        this.cookies = new Cookies;

        this.save_btn.addEventListener('click', (event) => {
            event.preventDefault();
            this.update();
        });

        this.editors.forEach((editor) => {
            editor.addEventListener('keydown', function(event) {
                if(event.key == 'Tab') {
                    event.preventDefault();
                    var start = this.selectionStart;
                    var end = this.selectionEnd;

                    this.value = this.value.substring(0, start) + "\t" + this.value.substring(end);
                    this.selectionStart = this.selectionEnd = start + 1;
                }
            });

            //get initial values
            this.api.get({
                action: 'get_content',
                user_uuid: window.user.uuid,
                session_uuid: window.session_uuid,
                type: editor.parentElement.dataset.tabName
            }, (data) => {
                editor.innerHTML = data.content;
            });
        });

        this.frame.setAttribute('src', 'embed.php?user_uuid=' + window.user.uuid + '&session_uuid=' + window.session_uuid);
    }

    update() {
        this.api.post({
            action: 'update_content',
            user_uuid: window.user.uuid,
            session_uuid: window.session_uuid
        }, {
            php: this.getEditor('php').value,
            css: this.getEditor('css').value,
            js: this.getEditor('js').value
        }, (data) => {
            this.frame.contentWindow.location.reload();
        });
    }

    getEditor(type) {
        let found_editor = null;

        this.editors.forEach((editor) => {
            if(editor.parentElement.dataset.tabName == type) {
                found_editor = editor;
            }
        });

        return found_editor;
    }
}
