import GetData from './GetData';

export default class AdminOptions {
    constructor(root) {
        this.root = root;
        this.api = new GetData;
        this.options_button = this.root.querySelector('.js-admin-options-button');
        this.options_window = this.root.querySelector('.js-admin-options-window');

        this.add_session_button = this.root.querySelector('.js-admin-options-add-session');

        this.sessions_window = this.root.querySelector('.js-admin-options-sessions');
        this.users_window = this.root.querySelector('.js-admin-options-users');
        this.content_window = this.root.querySelector('.js-admin-options-content');

        if(window.user.role == 'admin') {
            this.root.style.display = 'block';

            this.options_button.parentElement.addEventListener('click', () => {
                if(this.options_window.classList.contains('--active')) {
                    this.options_window.classList.remove('--active');
                    this.options_button.innerText = 'Options';
                } else {
                    this.render();
                    this.options_window.classList.add('--active');
                    this.options_button.innerText = 'Close';
                }
            });
        }
    }

    render() {
        //clear previous data
        this.sessions_window.innerHTML = '<p>Loading...</p>';
        this.users_window.innerHTML = '<p>Loading...</p>';
        this.content_window.innerHTML = '<p>Loading...</p>';
    }
}
