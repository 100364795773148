export default class GetData {
    fetch(type, params, post, callback) {
        let body = {};

        if(type == 'POST') {
            body = {
                body: JSON.stringify(post)
            }
        }

        fetch("api.php?" + new URLSearchParams(params).toString(), {
            method: type,
            headers: new Headers({ 'Content-Type': 'application/json' }),
            ...body
        }).then((response) => {
            response.text().then((text) => {
                callback(JSON.parse(text));
            })
        });
    }

    get(params, callback) {
        this.fetch('GET', params, null, callback);
    }

    post(params, post, callback) {
        this.fetch('POST', params, post, callback);
    }
}
