import Tabs from './modules/Tabs';
import Editor from './modules/Editor';
import AuthorWindows from './modules/AuthorWindows';
import User from './modules/User';
import AdminOptions from './modules/AdminOptions';

document.addEventListener('DOMContentLoaded', () => {
    let tabs = document.querySelector('.js-tabs');
    if(tabs) {
        new Tabs(tabs);
    }

    new User(() => {
        let editor = document.querySelector('.js-editor');
        if(editor) {
            new Editor(editor);
        }

        let author_windows = document.querySelector('.js-author-windows');
        if(author_windows) {
            new AuthorWindows(author_windows);
        }

        let admin_options = document.querySelector('.js-admin-options');
        if(admin_options) {
            new AdminOptions(admin_options);
        }
    });
});
