import GetData from './GetData';

export default class AuthorWindows {
    constructor(root) {
        this.root = root;
        this.api = new GetData;
        this.authors = [];
        this.resize_timeout = null;
        this.main_frame = document.querySelector('.js-editor-frame');

        this.update();

        setInterval(() => {
            this.update();
        }, 3000);

        window.addEventListener("resize", () => {
            clearTimeout(this.resize_timeout);

            this.resize_timeout = setTimeout(() => {
                this.resize();
            }, 500);
        });
    }

    resize() {
        let first_author_frame = this.root.querySelector('.js-author-frame');

        if(first_author_frame) {
            let main_size = this.main_frame.getBoundingClientRect();
            let author_size = first_author_frame.getBoundingClientRect();

            Object.keys(this.authors).forEach((author_key) => {
                this.authors[author_key].querySelector('.js-author-frame-window').style.height = main_size.height + 'px';
                this.authors[author_key].querySelector('.js-author-frame-window').style.width = main_size.width + 'px';
                this.authors[author_key].querySelector('.js-author-frame-window').style.transform = 'scale(' + ((1-((main_size.width - author_size.width) / main_size.width)) * 100) + '%)';
            });
        }
    }

    update() {
        this.api.get({
            action: 'get_session_users',
            user_uuid: window.user.uuid,
            session_uuid: window.session_uuid
        }, (data) => {
            let user_uuids = [];
            data.users.forEach((user) => {
                user_uuids.push(user.user_uuid);

                if(!Object.keys(this.authors).includes(user.user_uuid)) {
                    let active_class = null;

                    if(this.isActive(user.heartbeat)) {
                        active_class = '--active';
                    }

                    this.root.innerHTML += `
                        <div class="other-content-windows__window js-author-frame" data-user-uuid="${user.user_uuid}" data-last-heartbeat="${user.heartbeat}">
                            <iframe class="other-content-windows__window__frame js-author-frame-window" src="embed.php?user_uuid=${user.user_uuid}&session_uuid=${window.session_uuid}"></iframe>
                            <p class="other-content-windows__window__author ${active_class} js-active-label">${user.name}</p>
                        </div>
                    `;

                    this.authors[user.user_uuid] = this.root.querySelector('.js-author-frame[data-user-uuid="' + user.user_uuid + '"]');
                } else {
                    if(this.isActive(user.heartbeat)) {
                        this.authors[user.user_uuid].querySelector('.js-active-label').classList.add('--active');

                        if(user.heartbeat > this.authors[user.user_uuid].dataset.lastHeartbeat) {
                            this.authors[user.user_uuid].querySelector('.js-author-frame-window').contentWindow.location.reload();
                            this.authors[user.user_uuid].dataset.lastHeartbeat = user.heartbeat;
                        }
                    } else {
                        this.authors[user.user_uuid].querySelector('.js-active-label').classList.remove('--active');
                    }
                }
            });

            let remove_uuids = Object.keys(this.authors).filter(x => !user_uuids.includes(x));
            remove_uuids.forEach((remove_uuid) => {
                this.authors[remove_uuid].remove();
                delete this.authors[remove_uuid];
            });

            this.resize();
        });
    }

    isActive(heartbeat) {
        let now = Math.round(Date.now() / 1000);

        if(now - heartbeat < 180) {
            return true;
        }

        return false;
    }
}
