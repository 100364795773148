import GetData from './GetData';
import Cookies from './Cookies';

export default class User {
    constructor(callback) {
        this.cookies = new Cookies;
        this.api = new GetData;
        this.user_uuid = this.cookies.get('user_uuid');
        this.session_uuid = this.cookies.get('session_uuid');
        this.callback = callback;

        if(!this.user_uuid) {
            this.registerUser();
        } else {
            this.getUser();
        }
    }

    registerUser() {
        let name = null;
        while(!name) {
            name = prompt('Please enter your name:');
        }

        this.api.post({
            action: 'register_user'
        }, {
            name: name
        }, (data) => {
            if(data.uuid) {
                this.cookies.set('user_uuid', data.uuid, 365);
                this.user_uuid = data.uuid;
                this.getUser();
            } else {
                //error
                this.registerUser();
            }
        });
    }

    getUser() {
        if(this.user_uuid) {
            this.api.get({
                action: 'get_user',
                user_uuid: this.user_uuid
            }, (data) => {
                if(data.user) {
                    window.user = data.user;
                    this.getSessionUUID();
                } else {
                    //error
                    this.registerUser();
                }
            });
        }
    }

    getSessionUUID(force_input = false) {
        let session = null;

        if(this.session_uuid && !force_input) {
            session = this.session_uuid;
        } else {
            while(!session) {
                session = prompt('Please enter your session UUID:');
            }
        }

        this.api.get({
            action: 'set_session',
            user_uuid: this.user_uuid,
            session_uuid: session,
        }, (data) => {
            if(data.session == true) {
                this.cookies.set('session_uuid', session, 1);
                window.session_uuid = session;
                this.callback();
            } else {
                //error
                this.getSessionUUID(true);
            }
        });
    }
}
